import React from "react"
import { graphql } from "gatsby"
import AcademicJournal from "../components/Academic/index"
import Footer from "../components/footer"
import Layout from "../components/layout"

const Academic = props => {
  return (
    <Layout>
      <AcademicJournal acdmic={props.data.wpAcademicJournal} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpAcademicJournal(slug: { eq: $slug }) {
      id
      slug
      uri
      date(formatString: "MMMM DD, YYYY")
      title
      academicJournal {
        academicContent
        academicExcerpt
        academicImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        academicFiles {
          academicFile {
            title
            description
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Academic
